import React from 'react';

const Footer = () => {
    return (
        <>
            <footer className="container">
                <hr/>
                <p>&copy; 2024 - NobleSkywave</p>
            </footer>
        </>
    );
};

export default Footer;