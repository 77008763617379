import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const error = queryParams.get('error');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password });
      const { token, user } = response.data;

      // Save the token in localStorage
      localStorage.setItem('authToken', token);

      // Save user data in sessionStorage
      sessionStorage.setItem('userID', user.userID);
      sessionStorage.setItem('teamName', user.teamName);
      sessionStorage.setItem('callSign', user.callSign);

      // Redirect to dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error('Error during login:', error);
      setLoginError('Invalid email or password');
    }
  };

  return (
      <div className="container">
        {error && (
            <div className="alert alert-danger" role="alert">
              <p>{error}</p>
            </div>
        )}
        {loginError && (
            <div className="alert alert-danger" role="alert">
              <p>{loginError}</p>
            </div>
        )}
        <section className="h-100 gradient-form">
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-xl-10">
                <div className="card rounded-3">
                  <div className="row g-0">
                    <div className="col-lg-6">
                      <div className="card-body p-md-5 mx-md-4">
                        <div className="text-center">
                          <h4 className="mt-1 mb-5 pb-1">Noble Skywave</h4>
                        </div>
                        <form onSubmit={handleLogin}>
                          <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="emailAdd">Email</label>
                            <input
                                type="email"
                                id="emailAdd"
                                name="emailAdd"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="form-outline mb-4">
                            <label className="form-label" htmlFor="pass">Password</label>
                            <input
                                type="password"
                                id="pass"
                                name="pass"
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          <div className="pt-1 mb-5 pb-1">
                            <button className="btn btn-primary" type="submit">Log in</button>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                      <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                        <div style={{textAlign: 'center'}}>
                          <img src="/images/jimmy_logo.png" width="200px" alt="Noble Skywave Logo"/>
                        </div>
                        <h4 className="mb-4">Noble Skywave powered by the Communications & Electronics Branch of the
                          Canadian Armed Forces</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

  );
};

export default Login;
