import React, { useState, useEffect } from 'react';
import data from '../../data/2024_competition/scores.json'; // Adjust path as necessary
import usersData from '../../data/2024_competition/users.json'; // Adjust path as necessary
import { MapContainer, TileLayer, Polyline, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// Helper function to generate intermediate points
const generateIntermediatePoints = (start, end, steps) => {
    const latDiff = (end[0] - start[0]) / steps;
    const lonDiff = (end[1] - start[1]) / steps;
    return Array.from({ length: steps }, (_, i) => [
        start[0] + latDiff * i,
        start[1] + lonDiff * i
    ]);
};

const AnimateContacts = () => {
    const storedCallsign = sessionStorage.getItem('callSign') || '';
    const [callsign] = useState(storedCallsign);
    const [contacts, setContacts] = useState([]);
    const [displayedContacts, setDisplayedContacts] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animatedPolyline, setAnimatedPolyline] = useState([]);

    // Retrieve and sort contacts for the selected callsign
    useEffect(() => {
        if (callsign) {
            const filteredData = data
                .filter(contact => contact.fromCS === callsign || contact.toCS === callsign)
                .sort((a, b) => new Date(a.time) - new Date(b.time));
            setContacts(filteredData);
        }
    }, [callsign]);

    // Animation effect for contacts and polyline drawing
    useEffect(() => {
        if (contacts.length > 0 && currentIndex < contacts.length) {
            const fromLocation = getUserLocation(contacts[currentIndex].fromCS);
            const toLocation = getUserLocation(contacts[currentIndex].toCS);

            if (fromLocation && toLocation) {
                const steps = 50; // Number of intermediate points for smoothness
                const intermediatePoints = generateIntermediatePoints(fromLocation, toLocation, steps);

                // Incrementally add points to the polyline
                let pointIndex = 0;
                const timer = setInterval(() => {
                    setAnimatedPolyline(prev => [...prev, intermediatePoints[pointIndex]]);
                    pointIndex += 1;
                    if (pointIndex === steps) {
                        clearInterval(timer);
                        setDisplayedContacts(prev => [...prev, contacts[currentIndex]]);
                        setCurrentIndex(currentIndex + 1);
                        setAnimatedPolyline([]); // Clear polyline for the next contact
                    }
                }, 50); // Adjust for smoother or faster animation

                return () => clearInterval(timer);
            }
        }
    }, [contacts, currentIndex]);

    // Helper function to get user location from callSign
    const getUserLocation = (callSign) => {
        const user = usersData.find(user => user.callSign === callSign);
        return user ? [user.latitude, user.longitude] : null;
    };

    return (
        <div className="container">
            <br/>
            <h4>Animating Contacts for Callsign: {callsign}</h4>

            {/* Map display */}
            <MapContainer center={[0, 0]} zoom={2} style={{ height: "400px", width: "100%" }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                />

                {/* Display animated polyline for current transmission */}
                {animatedPolyline.length > 0 && (
                    <Polyline positions={animatedPolyline} color="blue" />
                )}

                {/* Display markers and lines for completed transmissions */}
                {displayedContacts.map(contact => {
                    const fromLocation = getUserLocation(contact.fromCS);
                    const toLocation = getUserLocation(contact.toCS);

                    if (fromLocation && toLocation) {
                        return (
                            <React.Fragment key={contact.id}>
                                <Marker position={fromLocation}>
                                    <Popup>{`From: ${contact.fromCS}`}</Popup>
                                </Marker>
                                <Marker position={toLocation}>
                                    <Popup>{`To: ${contact.toCS}`}</Popup>
                                </Marker>
                                <Polyline positions={[fromLocation, toLocation]} color="blue" />
                            </React.Fragment>
                        );
                    }
                    return null;
                })}
            </MapContainer>

            {/* Table display */}
            <table className="table table-striped table-bordered table-hover mt-3">
                <thead className="thead-dark">
                <tr>
                    <th>ID</th>
                    <th>From Callsign</th>
                    <th>To Callsign</th>
                    <th>Mode</th>
                    <th>Transmission</th>
                    <th>Time</th>
                    <th>Event</th>
                </tr>
                </thead>
                <tbody>
                {displayedContacts.map((contact) => (
                    <tr key={contact.id}>
                        <td>{contact.id}</td>
                        <td>{contact.fromCS}</td>
                        <td>{contact.toCS}</td>
                        <td>{contact.mode || 'N/A'}</td>
                        <td>{contact.trans || 'N/A'}</td>
                        <td>{contact.time}</td>
                        <td>{contact.event}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default AnimateContacts;