import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';

const GetContacts = () => {
    const storedCallsign = sessionStorage.getItem('callSign') || '';
    const [callsign] = useState(storedCallsign);
    const [filteredContacts, setFilteredContacts] = useState([]);

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/scores`);
                const data = await response.json();
                const filteredData = data.filter(
                    contact => contact.fromCS === callsign || contact.toCS === callsign
                );
                setFilteredContacts(filteredData);
            } catch (error) {
                console.error("Error fetching contacts:", error);
            }
        };
        fetchContacts();
    }, [callsign]);

    const exportToJson = () => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(filteredContacts, null, 2)
        )}`;
        const link = document.createElement('a');
        link.href = jsonString;
        link.download = `contacts_${callsign}.json`;
        link.click();
    };

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredContacts);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Contacts');
        XLSX.writeFile(workbook, `contacts_${callsign}.xlsx`);
    };

    return (
        <div className="container">
            <h4>Contact List for Callsign: {callsign}</h4>
            <a href="/dashboard/contacts/annimation" className="btn btn-primary">Watch Animation of Score Data</a>
            <button onClick={exportToJson} className="btn btn-secondary mr-2">Export to JSON</button>
            <button onClick={exportToExcel} className="btn btn-secondary">Export to Excel</button>

            {filteredContacts.length > 0 ? (
                <table className="table">
                    <thead><tr><th>ID</th><th>From Callsign</th><th>To Callsign</th><th>Details</th></tr></thead>
                    <tbody>
                    {filteredContacts.map((contact) => (
                        <tr key={contact.id}>
                            <td>{contact.id}</td>
                            <td>{contact.fromCS}</td>
                            <td>{contact.toCS}</td>
                            <td><Link to={`/dashboard/contact/${contact.id}`} className="btn btn-primary">View Details</Link></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            ) : (
                <p>No contacts found for callsign "{callsign}".</p>
            )}
        </div>
    );
};

export default GetContacts;
