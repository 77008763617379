
const About = () => {
    return (
        <>
            <div className="container">
                <br/>
                <h4>About Noble Skywave</h4>
                <br />
                <h5>
                    Since 2013, the Canadian Communications and Electronics branch has brought hundreds of teams from dozens of Nations together to test, strengthen expertise and compete in a friendly atmosphere to what is now known as the most Prestigious military led HF competition in the world: Noble Skywave.
                </h5>
                <h5>
                    Noble Skywave acquired its “letters of nobility” through highly skilled and proud participating teams which are either HAM/CFARS/MARS or Military Radio Operators around the globe. As the Leading Nation for this event, the Canadian Armed Forces are committed to provide the best possible training experience to all participants and is looking forward again this year to crown the best HF Radio Operators in the world.
                </h5>
            </div>
        </>
    );
};

export default About;