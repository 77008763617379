import React, { useEffect, useState } from 'react';

const Results2024 = () => {
    const [scores, setScores] = useState([]);
    const [ssbScores, setSsbScores] = useState([]);
    const [ns1Scores, setNs1Scores] = useState([]);
    const [ns2Scores, setNs2Scores] = useState([]);
    const [aleScores, setAleScores] = useState([]);

    useEffect(() => {
        const fetchScores = async () => {
            try {
                const responses = await Promise.all([
                    fetch('/results/scores.json'),
                    fetch('/results/scores_SSB.json'),
                    fetch('/results/scores_2GNS1.json'),
                    fetch('/results/scores_2GNS2.json'),
                    fetch('/results/scores_3GALE.json')
                ]);

                const data = await Promise.all(responses.map(response => response.json()));
                setScores(data[0]);
                setSsbScores(data[1]);
                setNs1Scores(data[2]);
                setNs2Scores(data[3]);
                setAleScores(data[4]);
            } catch (error) {
                console.error("Error loading scores:", error);
            }
        };

        fetchScores();
    }, []);

    const createTable = (jsonData, tableTitle) => (
        <div className="table-container">
            <h4>{tableTitle}</h4>
            <table className="table table-hover table-striped">
                <thead>
                <tr>
                    <th>Call Sign</th>
                    <th>Total Points</th>
                </tr>
                </thead>
                <tbody>
                {jsonData.map((item, index) => (
                    <tr key={index}>
                        <td>{item.callSign}</td>
                        <td>{item.totalPoints}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <>
            <div className="container">
                <br/>
                <h1 className="text-center my-20">Noble Skywave 2024 Results</h1>
                <br/>
                <div className="content" style={{ textAlign: 'left' }}>
                    <h3><b>Winners by Category</b></h3>
                    <ul>
                        <li><strong>1-150W Winner:</strong> Joint Communications Support Element</li>
                        <li><strong>150-400W Winner:</strong> Inferior Groundwave</li>
                        <li><strong>400W+ Winner:</strong> HF Hooligans - Garden Ridge</li>
                        <li><strong>Team Challenge SSB Winner:</strong> Inferior Groundwave</li>
                        <li><strong>Team Challenge 2G ALE Group 1 Winner (Tie):</strong> 2KFAAR, 7MJ, Gumby, Thunderbolt</li>
                        <li><strong>Team Challenge 2G ALE Group 2 Winner:</strong> Blackhorse</li>
                        <li><strong>Team Challenge 3G ALE Winner:</strong> Joint Communications Support Element</li>
                        <li><strong>Longest Contact:</strong> UY289 to KR217 (Distance 19,652km)</li>
                    </ul>
                </div>
                <div id="tables-container">
                    {createTable(scores, "Overall Scores")}
                    {createTable(ssbScores, "Team Challenge - SSB Scores")}
                    {createTable(ns1Scores, "Team Challenge - 2GNS1 Scores")}
                    {createTable(ns2Scores, "Team Challenge - 2GNS2 Scores")}
                    {createTable(aleScores, "Team Challenge - 3G ALE Scores")}
                </div>
            </div>
        </>
    );
};

export default Results2024;