import React from 'react';

const Header = () => {
    const userID = sessionStorage.getItem('userID');
    const teamName = sessionStorage.getItem('teamName');
    const callSign = sessionStorage.getItem('callSign');

    const handleLogout = () => {
        localStorage.removeItem('authToken');  // Clear the auth token
        sessionStorage.clear();  // Clear session storage data
        window.location.href = '/login';  // Redirect to login page
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-fixed-top" data-bs-theme="dark" style={{ backgroundColor: 'var(--bs-content-bg)', borderBottom: 'var(--bs-border-width) solid var(--bs-content-border-color)' }}>
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        Noble Skywave
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-collapse-1" aria-controls="navbar-collapse-1" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbar-collapse-1">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/about">About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href={`${process.env.REACT_APP_API_URL}/support.php`}>Support</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href={`${process.env.REACT_APP_API_URL}/dashboard`}>Competition Dashboard</a>
                            </li>
                        </ul>
                        {userID ? (
                            <li className="dropdown text-white">
                                <button className="nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-solid fa-user"></i>&nbsp;{userID}
                                </button>
                                <div className="dropdown-menu mt-lg-2 rounded-top-0">
                                    <p><b>Team Name: </b>{teamName}</p>
                                    <p><b>Callsign: </b>{callSign}</p>
                                    <button onClick={handleLogout} className="btn btn-danger">Logout</button>
                                </div>
                            </li>
                        ) : (
                            <a className="btn btn-primary" href="/login">Login</a>
                        )}
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Header;
