// src/middleware/withMiddleware.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withMiddleware = (WrappedComponent, middleware) => {
    return (props) => {
        const navigate = useNavigate();

        useEffect(() => {
            if (middleware) {
                middleware(navigate);
            }
        }, [navigate, middleware]);

        return <WrappedComponent {...props} />;
    };
};

export default withMiddleware;
