import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Polyline, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useParams } from 'react-router-dom';

// Helper function to generate intermediate points
const generateIntermediatePoints = (start, end, steps) => {
    const latDiff = (end[0] - start[0]) / steps;
    const lonDiff = (end[1] - start[1]) / steps;
    return Array.from({ length: steps }, (_, i) => [
        start[0] + latDiff * i,
        start[1] + lonDiff * i
    ]);
};

const MapContactsByCallsign = () => {
    const { input_callsign } = useParams();
    const [callsign] = useState(input_callsign);
    const [contacts, setContacts] = useState([]);
    const [displayedContacts, setDisplayedContacts] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animatedPolyline, setAnimatedPolyline] = useState([]);

    // Retrieve and sort contacts for the selected callsign
    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/scores`);
                const data = await response.json();
                const filteredData = data
                    .filter(contact => contact.fromCS === callsign || contact.toCS === callsign)
                    .sort((a, b) => new Date(a.time) - new Date(b.time));
                setContacts(filteredData);
            } catch (error) {
                console.error("Error fetching contacts:", error);
            }
        };

        fetchContacts();
    }, [callsign]);

    // Animation effect for contacts and polyline drawing
    useEffect(() => {
        if (contacts.length > 0 && currentIndex < contacts.length) {
            const fromLocation = getUserLocation(contacts[currentIndex].fromCS);
            const toLocation = getUserLocation(contacts[currentIndex].toCS);

            if (fromLocation && toLocation) {
                const steps = 50;
                const intermediatePoints = generateIntermediatePoints(fromLocation, toLocation, steps);

                let pointIndex = 0;
                const timer = setInterval(() => {
                    setAnimatedPolyline(prev => [...prev, intermediatePoints[pointIndex]]);
                    pointIndex += 1;
                    if (pointIndex === steps) {
                        clearInterval(timer);
                        setDisplayedContacts(prev => [...prev, contacts[currentIndex]]);
                        setCurrentIndex(currentIndex + 1);
                        setAnimatedPolyline([]);
                    }
                }, 50);

                return () => clearInterval(timer);
            }
        }
    }, [contacts, currentIndex]);

    // Helper function to get user location from callSign
    const getUserLocation = async (callSign) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users/public`);
            const usersData = await response.json();
            const user = usersData.find(user => user.callSign === callSign);
            return user ? [user.latitude, user.longitude] : null;
        } catch (error) {
            console.error("Error fetching user location:", error);
            return null;
        }
    };

    // Hide Header and Footer when component mounts
    useEffect(() => {
        const header = document.querySelector('nav');
        const footer = document.querySelector('footer');
        if (header) header.style.display = 'none';
        if (footer) footer.style.display = 'none';

        return () => {
            if (header) header.style.display = '';
            if (footer) footer.style.display = '';
        };
    }, []);

    return (
        <MapContainer center={[0, 0]} zoom={2} style={{ height: "400px", width: "100%" }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; OpenStreetMap contributors'
            />

            {animatedPolyline.length > 0 && (
                <Polyline positions={animatedPolyline} color="blue" />
            )}

            {displayedContacts.map(contact => {
                const fromLocation = getUserLocation(contact.fromCS);
                const toLocation = getUserLocation(contact.toCS);

                if (fromLocation && toLocation) {
                    return (
                        <React.Fragment key={contact.id}>
                            <Marker position={fromLocation}>
                                <Popup>{`From: ${contact.fromCS}`}</Popup>
                            </Marker>
                            <Marker position={toLocation}>
                                <Popup>{`To: ${contact.toCS}`}</Popup>
                            </Marker>
                            <Polyline positions={[fromLocation, toLocation]} color="blue" />
                        </React.Fragment>
                    );
                }
                return null;
            })}
        </MapContainer>
    );
};

export default MapContactsByCallsign;
