import React from 'react';
import Map from '../components/Map';
const Home = () => {
    return (
        <>
            <div className="container body-content">
                <div className="row homepage">
                    <div className="col-md-12 text-center">
                        <img src="/images/logo.png" alt="Noble Skywave" className="img-responsive center-block"
                             width="762" height="197"/>
                        <h2 className="center-block">Crowning the best HF Operators in the world.</h2>
                        <br/>
                        <a href={`/2024-results`} className="btn btn-lg btn-primary">2024 Results</a><br/><br/>
                        <a href={`https://forms.gle/13unEWKeJqwk3caFA`} className="btn btn-lg btn-outline-primary">2025
                            Mailing List</a><br/><br/>
                        <a href={`mailto:nobleskywave1@gmail.com`} className="btn btn-lg btn-outline-primary">
                            Contact Us</a>

                    </div>
                </div>
                <br/><br/>
                <div className="content">
                    <div className="col-md-12 text-center">
                        <Map />
                    </div>
                </div>
            </div>
            <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAmR31lWLAYWwR_GrVvkbZe52ULRRUJInU&callback=initMap" async></script>
            <script src="/js/map.js"></script>
        </>
    );
};

export default Home;