import React from 'react';
const FourOFour = () => {
    return (
        <>
            <div className="container body-content">
                <h1>404 Error</h1>
                <p>Page not found. </p>
            </div>
        </>
    );
};

export default FourOFour;