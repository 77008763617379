import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import usersData from '../data/2024_competition/users.json';

// Fix for default marker icon issue with Webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
    iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const UserMap = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        // Fetch user data
        setUsers(usersData);
    }, []);

    return (
        <MapContainer center={[20, 0]} zoom={2} style={{ height: '600px', width: '100%' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {users.map(user => (
                <Marker key={user.userID} position={[user.latitude, user.longitude]}>
                    <Popup>
                        <div>
                            <strong>{user.teamName}</strong><br />
                            {user.unitName}<br />
                            {user.callSign}
                        </div>
                    </Popup>
                </Marker>
            ))}
        </MapContainer>
    );
};

export default UserMap;