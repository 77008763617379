import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import "halfmoon/css/halfmoon.min.css";

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
// Middleware

import withMiddleware from './middleware/withMiddlewear';
import authMiddleware from './middleware/jsonAuthMiddlewear';

// Pages
import FourOFour from './pages/404';
import Home from './pages/Home';
import About from './pages/About';
import Results from './pages/2024-Results';

//Dashboard
import Dashboard_Home from './dashboard/Home';
import ContactDetail from './dashboard/components/contact_details';
import ContactAnimation from './dashboard/components/animate_contacts';

//Data View
import Map_Contacts_by_Callsign from "./data/views/map_contacts_by_callsign";


function App() {
    const Dashboard_Home_Protected = withMiddleware(Dashboard_Home, authMiddleware);
    return (
        <div className="App">
            <Router>
                <Header />
                <main>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/2024-results" element={<Results />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/dashboard" element={<Dashboard_Home />} />
                        <Route path="/dashboard/contact/:id" element={<ContactDetail />} />
                        <Route path="/dashboard/contacts/annimation" element={<ContactAnimation />} />
                        <Route path="/data/views/contacts-annimated/:input_callsign" element={<Map_Contacts_by_Callsign />} />
                        <Route path="*" element={<FourOFour />} />
                    </Routes>
                </main>
                <Footer />
            </Router>
        </div>
    );
}

export default App;
