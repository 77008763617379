import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MapContainer, TileLayer, Polyline, Marker, Popup } from 'react-leaflet';

const haversineDistance = (coords1, coords2) => {
    // Calculations...
};

const ContactDetail = () => {
    const { id } = useParams();
    const [contact, setContact] = useState(null);
    const [fromUser, setFromUser] = useState(null);
    const [toUser, setToUser] = useState(null);
    const [distance, setDistance] = useState(null);

    useEffect(() => {
        const fetchContactData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/scores/${id}`);
                const contactData = await response.json();
                setContact(contactData);

                // Fetch the users associated with the contact
                const fromUserResponse = await fetch(`${process.env.REACT_APP_API_URL}/users/public`);
                const users = await fromUserResponse.json();

                const fromUser = users.find(user => user.callSign === contactData.fromCS);
                const toUser = users.find(user => user.callSign === contactData.toCS);
                setFromUser(fromUser);
                setToUser(toUser);

                if (fromUser && toUser) {
                    const calculatedDistance = haversineDistance(
                        [fromUser.latitude, fromUser.longitude],
                        [toUser.latitude, toUser.longitude]
                    );
                    setDistance(calculatedDistance.toFixed(2));
                }
            } catch (error) {
                console.error("Error fetching contact data:", error);
            }
        };
        fetchContactData();
    }, [id]);

    if (!contact || !fromUser || !toUser) return <p>Loading contact details...</p>;

    const positionFrom = [fromUser.latitude, fromUser.longitude];
    const positionTo = [toUser.latitude, toUser.longitude];

    return (
        <div className="container">
            <h2>Contact Details</h2>
            <p><strong>From Callsign:</strong> {contact.fromCS}</p>
            <p><strong>To Callsign:</strong> {contact.toCS}</p>
            <p><strong>Calculated Distance:</strong> {distance} km</p>

            <MapContainer center={positionFrom} zoom={5} style={{ height: "400px", width: "100%" }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; OpenStreetMap' />
                <Marker position={positionFrom}><Popup>{`From: ${fromUser.callSign}`}</Popup></Marker>
                <Marker position={positionTo}><Popup>{`To: ${toUser.callSign}`}</Popup></Marker>
                <Polyline positions={[positionFrom, positionTo]} color="blue" />
            </MapContainer>
        </div>
    );
};

export default ContactDetail;
