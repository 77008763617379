import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Contacts from './components/get_contacts';

const Dashboard_Home = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const userID = sessionStorage.getItem('userID');
        if (!userID) {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <div className="container">
            <br/>
            <h1>Noble Skywave Dashboard</h1>
            <hr/>
            <div className="card">
                <div className="card-header">2024 Contacts</div>
                <div className="card-body">
                    <Contacts />
                </div>
            </div>
        </div>
    );
};

export default Dashboard_Home;
